/* Copyright 2020 VMware, Inc. All rights reserved. -- VMware Confidential */

// This function disabling pointer events was added in CL 4718353 to deal with Kendo test issues after Chrome 55
// But patching pointer events like this breaks Cypress :-(   Clicks generate errors "Construtor is not a constructor"
// The work-around is to skip this when running cypress tests, which deal only with ng-next-app views.
// (Another way could have been to update Kendo to a newer version but it was too risky!)
// TODO  Remove this file once Kendo is gone once and for all :-)

function disablePointerEventsFeatureDetection() {

   var targetObjects = [
      Element.prototype,
      HTMLElement.prototype,
      Document.prototype,
      window
   ];

   var propertiesToRemove = [
      'PointerEvent',
      'MSPointerEvent',
      'pointerover',
      'pointerenter',
      'pointerdown',
      'pointermove',
      'pointerup',
      'pointercancel',
      'pointerout',
      'pointerleave',
      'gotpointercapture',
      'lostpointercapture'
   ];

   for (var i = 0; i < targetObjects.length; i++) {
      var targetObject = targetObjects[i];

      for (var j = 0; j < propertiesToRemove.length; j++) {
         var propertyName = propertiesToRemove[j];

         try {
            targetObject[propertyName] = undefined;
            delete targetObject[propertyName];
         } catch (e) {
            console.warn(
               'Failed to remove "' +
               propertyName +
               '" PointerEvent related property from ' +
               targetObject.toString()
            );
         }
      }
   }
};
// Call it in unit test mode here.  See init.js for the runtime mode.
if (window.jasmine) {
   disablePointerEventsFeatureDetection();
}
